import { Box, Chip } from "@mui/material";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useRelocate } from "../../../../relocate";

interface Props {
  deleteFilter: any;
}

export const FilterTags = ({ deleteFilter }: Props) => {
  const { relocate } = useRelocate();
  let [searchParams] = useSearchParams();
  const selectedListCriteria = searchParams.getAll("fl[]") || [];
  const selectedFormatCriteria = searchParams.getAll("ff[]") || [];
  const selectedRangeCriteria = searchParams.getAll("fr[]") || [];
  const selectedSearch = searchParams.getAll("search") || [];

  return (
    <Box className="filter-list-chips">
      {selectedListCriteria &&
        selectedListCriteria.map((filter: string) => {
          const splitedCriteria = filter.split("+");
          let filterCriteriaId = `${splitedCriteria[0]}`;
          let filterLabel = `${splitedCriteria[1]}`;

          return (
            <Chip
              label={`${filterLabel}`}
              key={filter}
              onDelete={() => deleteFilter("list", filterCriteriaId)}
              className="filter-chip"
            />
          );
        })}
      {selectedFormatCriteria &&
        selectedFormatCriteria.map((filter: string) => {
          const splitedCriteria = filter.split("+");
          let filterCriteriaId = `${splitedCriteria[0]}`;
          let filterLabel = `${splitedCriteria[1]}`;

          return (
            <Chip
              label={filterLabel}
              key={filterLabel}
              onDelete={() => deleteFilter("format", filterCriteriaId)}
              className="filter-chip"
            />
          );
        })}
      {selectedRangeCriteria &&
        selectedRangeCriteria.map((filter: string) => {
          debugger;
          let rangeLabel = `${filter.split("+")[1]}`;
          let rangeValue: string = `${filter.split("+")[2]}-${
            filter.split("+")[3]
          }`;
          if (rangeValue) {
            rangeLabel = `${rangeLabel} (${rangeValue})`;
          }

          return (
            <Chip
              label={`${rangeLabel}`}
              key={filter}
              onDelete={() => deleteFilter("range", filter)}
              className="filter-chip"
            />
          );
        })}
      {/* {selectedBrand && selectedBrand !== "all" && (
        <Chip
          label={selectedBrand}
          key={selectedBrand}
          onDelete={() => {
            relocate({
              pathname: "/browse",
              search: `?${createSearchParams({
                brand: "all",
                sort: "DOWNLOADS_DESC",
              })}`,
            });
          }}
          className="filter-chip"
        />
      )} */}
    </Box>
  );
};
