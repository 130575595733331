import { useContext, useEffect } from "react";
import { isExpired } from "react-jwt";
import { getAccessToken } from "../API/authenticate.api";
import { useRelocate } from "../relocate";
import { extractAuthCode } from "../Utils/authenticate";
import { AuthContext } from "../Embed/Context/AuthContext";

export const Authenticate = () => {
  const { redirect } = useRelocate();
  useEffect(() => {
    const authCode: string | null = extractAuthCode(window.location.href);
    if (authCode != null) {
      getAccessToken(authCode).then((accesstoken: any) => {
        debugger;
        const isMyTokenExpired = isExpired(accesstoken);
        if (!isMyTokenExpired) {
          if (window.name === "embedpopup") {
            window.opener.postMessage(
              { token: accesstoken, isToken: "true" },
              window.location.origin
            );
            // window.opener.location.reload();
            window.close();
          } else {
            const prevLoginState = localStorage.getItem("loginstate");
            if (prevLoginState) {
              redirect(`${prevLoginState}`, true);
            } else {
              redirect(`/`, true);
            }
          }
        }
      });
    }
  }, []);

  return <></>;
};
