import _, { findIndex, forEach, includes, map } from "lodash";
import * as React from "react";
import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { getAllProductDetails, ListBrands } from "../API";

interface IBimlibType {
  products: string[];
  region: string;
  downloadedFiles: { productId: string; fileId: string }[];
}

const inititialState: IBimlibType = {
  products: [],
  region: "",
  downloadedFiles: [],
};

interface countType {
  products: string[];
}

const initialState: countType = {
  products: [],
};

export type BimlibContextType = {
  basket: IBimlibType;
  productsDetail: any;
  brands: any;
  allDownloadedFiles: any;
  toggleBasketProduct: (productid: string) => void;
  addRemoveProduct: (productid: string, region: any) => void;
  initBasket: (regionname: string) => void;
  addDownloadFiles: (fileid: { productId: string; fileId: string }[]) => void;
  removeBasketProduct: (productId: string[]) => void;
  getAllProduct: (type: string, region: any) => void;
  addDeletedFiles: (productId: string, fileId: string, region: any) => void;
  removeProduct: (productId: string, region: any) => void;
  count: countType;
};

export const BimlibContext = createContext<BimlibContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const BasketProvider: React.FC<Props> = ({ children }) => {
  const [basket, setBasketProducts] = useState<IBimlibType>(inititialState);
  const [count, setCount] = useState<countType>(initialState);
  const [brands, setBrands] = useState<any>(null);
  const [productsDetail, setProductsDetail] = useState<any>([]);
  const [allDownloadedFiles, setAllDownloadedFiles] = useState<any[]>([]);
  const [allFiles, setAllFiles] = useState<any[]>([]);
  const idb = window.indexedDB;
  const { locale } = useParams();

  useEffect(() => {
    if (basket.region) {
      const cokkies = new Cookies();
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 100);
      cokkies.set(`basket_${basket.region}`, basket, {
        path: "/",
        expires: date,
      });
    }
  }, [basket]);

  useEffect(() => {
    validateCollection(createCollectionIndexed);
    getAllProduct("get", "");
  }, []);

  const initBasket = (regionname: string) => {
    if (regionname) {
      const cokkies = new Cookies();
      const productsFromCookie = cokkies.get(`basket_${regionname}`);
      if (productsFromCookie) {
        setBasketProducts(productsFromCookie);
      } else {
        setBasketProducts({
          products: [],
          region: regionname,
          downloadedFiles: [],
        });
        clearObjectStore();
        //to be discuss with yogesh for cookie
        // clearProducts();
        // clearFiles();
      }
    }
  };

  const clearObjectStore = () => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("DownloadedFiles", "readwrite");
      const downloadedFiles = transaction.objectStore("DownloadedFiles");
      downloadedFiles.clear();
      transaction.oncomplete = () => {
        db.close();
      };
    };
    dbPromise.onerror = (event) => {
      return event;
    };
  };

  const clearProducts = () => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("Products", "readwrite");
      const product = transaction.objectStore("Products");
      product.clear();
      transaction.oncomplete = () => {
        db.close();
      };
    };
    dbPromise.onerror = (event) => {
      return event;
    };
  };

  const clearFiles = () => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("Files", "readwrite");
      const files = transaction.objectStore("Files");
      files.clear();
      transaction.oncomplete = () => {
        db.close();
      };
    };
    dbPromise.onerror = (event) => {
      return event;
    };
  };

  const getAllProduct = (type: string, region: any) => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("Products", "readonly");
      const productData = transaction.objectStore("Products");
      const products = productData.getAll();
      products.onsuccess = async (query: any) => {
        let productId: any = map(query.target?.result, "productId");
        if (type === "create") {
          addFilesObject(productId, region);
        }
        setCount({
          products: query.target?.result,
        });
        setProductsDetail(query.target?.result);
      };
      products.onerror = (event) => {
        console.log("error occured");
      };
      transaction.oncomplete = () => {
        db.close();
      };
    };
  };

  const getAllFiles = () => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transactionFiles = db.transaction("Files", "readonly");
      const filesData = transactionFiles.objectStore("Files");
      const files = filesData.getAll();
      files.onsuccess = async (query: any) => {
        setAllFiles(query.target?.result);
      };
      files.onerror = (event) => {
        console.log("error occured");
      };
      transactionFiles.oncomplete = () => {
        db.close();
      };
    };
  };

  const getAllDownloadedFiles = (productId: any, region: any) => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = async () => {
      const db = dbPromise.result;
      const transaction = db.transaction("DownloadedFiles", "readonly");
      const productData = transaction.objectStore("DownloadedFiles");
      const products = await productData.getAll();
      products.onsuccess = (query: any) => {
        //let productid = map(query.target?.result, "productId");
        query.target?.result.forEach((id: any) => {
          if (id.productId === productId) {
            removeDeletedFiles(id.fileId, region);
          }
        });
        setAllDownloadedFiles(query.target?.result);
        return query.target?.result;
      };
      products.onerror = (event) => {
        console.log("error occured");
      };
      transaction.oncomplete = () => {
        db.close();
      };
      return products;
    };
  };

  const createCollectionIndexed = () => {
    if (!idb) {
      return;
    }
    const request = idb.open("BimLibrary", 2);
    request.onerror = (event) => {
      console.log("error occured", event);
    };
    request.onupgradeneeded = (event) => {
      const db = request.result;
      if (event.oldVersion < 1) {
        if (!db.objectStoreNames.contains("Products")) {
          db.createObjectStore("Products", {
            keyPath: "productId",
          });
        }
        if (!db.objectStoreNames.contains("DownloadedFiles")) {
          db.createObjectStore("DownloadedFiles", {
            keyPath: "fileId",
          });
        }
        if (!db.objectStoreNames.contains("Files")) {
          db.createObjectStore("Files", {
            keyPath: "productId",
          });
        }
      }

      if (event.oldVersion < 2) {
        if (db.objectStoreNames.contains("Products")) {
          db.deleteObjectStore("Products");
          db.createObjectStore("Products", {
            keyPath: ["productId", "region"],
          });
        }
        if (db.objectStoreNames.contains("DownloadedFiles")) {
          db.deleteObjectStore("DownloadedFiles");
          db.createObjectStore("DownloadedFiles", {
            keyPath: ["fileId", "region"],
          });
        }
        if (db.objectStoreNames.contains("Files")) {
          db.deleteObjectStore("Files");
          db.createObjectStore("Files", {
            keyPath: ["productId", "region"],
          });
        }
      }
    };

    request.onsuccess = () => {};
  };

  const validateCollection = (createCollection = createCollectionIndexed) => {
    const request = idb.open("BimLibrary", 2);
    request.onerror = (event) => {
      console.log("validation failed", event);
    };
    request.onsuccess = () => {
      const db = request.result;
      try {
        const transaction = db.transaction("Products", "readonly");
        const downloadTransaction = db.transaction(
          "DownloadedFiles",
          "readonly"
        );
        const fileTransaction = db.transaction("Files", "readonly");
      } catch (e: unknown) {
        db.close();
        idb.deleteDatabase("BimLibrary");
      } finally {
        createCollection();
      }
    };
  };

  const toggleBasketProduct = (productid: string) => {
    if (basket.products.includes(productid)) {
      setBasketProducts((prevBasketProducts) => {
        return {
          ...prevBasketProducts,
          products: prevBasketProducts.products.filter(
            (product: string) => product !== productid
          ),
        };
      });
    } else {
      setBasketProducts((prevBasketProducts) => {
        return {
          ...prevBasketProducts,
          products: [...prevBasketProducts.products, productid],
          downloadedFiles: prevBasketProducts.downloadedFiles.filter(
            (dwdfiles: any) => productid !== dwdfiles.productId
          ),
        };
      });
    }
  };

  const addFilesObject = (productId: any, region: any) => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = async () => {
      const db = dbPromise.result;
      const response = await getAllProductDetails(productId, locale);
      const transaction = db.transaction("Files", "readwrite");
      const filesData = transaction.objectStore("Files");
      let files: any = forEach(response, (res) => {
        filesData.put({
          // id: productsDetail.length + 1,
          productId: res.productId,
          fileLength: res.fileDescriptors.length,
          region: region,
        });
      });
      setAllFiles(files);
      files.onsuccess = () => {
        transaction.oncomplete = () => {
          db.close();
          //  getAllFiles();
        };
      };

      files.onerror = (event: any) => {
        console.log("error occured", event);
      };
    };
  };

  const removeProduct = (id: any, region: any) => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("Products", "readwrite");
      const productData = transaction.objectStore("Products");
      const products = productData.delete([id, region]);
      products.onsuccess = (query: any) => {
        if (query.target?.result !== undefined) {
          setProductsDetail(query.target?.result);
        }
      };
      products.onerror = (event) => {
        console.log("error occured");
      };
      transaction.oncomplete = () => {
        db.close();
        getAllProduct("get", region);
      };
    };
  };

  const removeFiles = (id: any, region: any) => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("Files", "readwrite");
      const productData = transaction.objectStore("Files");
      const products = productData.delete([id, region]);
      products.onsuccess = (query: any) => {
        setAllFiles(query.target?.result);
      };
      products.onerror = (event) => {
        console.log("error occured");
      };
      transaction.oncomplete = () => {
        db.close();
        getAllFiles();
      };
    };
  };

  const addRemoveProduct = (productid: string, region: any) => {
    getAllProduct("get", region);
    const dbPromise = idb.open("BimLibrary", 2);
    //if (!includes(map(productsDetail, "productId"), productid) || !includes(map(productsDetail, "region"), region)) {
    if (
      !productsDetail.some(
        (p: any) => p.productId === productid && p.region === region
      )
    ) {
      dbPromise.onsuccess = () => {
        const db = dbPromise.result;
        const transaction = db.transaction("Products", "readwrite");
        const productData = transaction.objectStore("Products");
        getAllDownloadedFiles(productid, region);
        const products = productData.put({
          // id: productsDetail?.length + 1,
          productId: productid,
          region: region,
        });
        products.onsuccess = () => {
          transaction.oncomplete = () => {
            db.close();
            getAllProduct("create", region);
          };
        };

        products.onerror = (event) => {
          console.log("error occured", event);
        };
      };
    } else {
      getAllDownloadedFiles(productid, region);
      let key: any;
      productsDetail.map((prod: any) => {
        if (prod.productId === productid) {
          key = prod.productId;
        }
      });
      if (allDownloadedFiles?.length > 0) {
        allDownloadedFiles.map((prod: any) => {
          if (prod.productId === productid) {
            removeDeletedFiles(prod.fileId, region);
          }
        });
      }

      removeProduct(key, region);
      removeFiles(key, region);
    }
  };

  const removeBasketProduct = (productid: string[]) => {
    setBasketProducts((prevBasketProducts) => {
      return {
        ...prevBasketProducts,
        products: prevBasketProducts.products.filter(
          (product: string) => !productid.includes(product)
        ),
      };
    });
  };

  const addDownloadFiles = (
    fileid: { productId: string; fileId: string }[]
  ) => {
    setBasketProducts((prevBasketProducts) => {
      return {
        ...prevBasketProducts,
        downloadedFiles: [...prevBasketProducts.downloadedFiles, ...fileid],
      };
    });
  };

  const addDeletedFiles = (productId: string, fileId: string, region: any) => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("DownloadedFiles", "readwrite");
      const productData = transaction.objectStore("DownloadedFiles");
      const products = productData.put({
        productId: productId,
        fileId: fileId,
        region: region,
      });
      products.onsuccess = () => {
        transaction.oncomplete = () => {
          db.close();
          return Promise.resolve(() => {});
        };
      };

      products.onerror = (event) => {
        console.log("error occured", event);
      };
    };
  };

  const removeDeletedFiles = (fileId: string, region: string) => {
    const dbPromise = idb.open("BimLibrary", 2);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const transaction = db.transaction("DownloadedFiles", "readwrite");
      const productData = transaction.objectStore("DownloadedFiles");
      const products = productData.delete([fileId, region]);
      products.onsuccess = (query: any) => {
        setAllDownloadedFiles(query.target?.result);
      };
      products.onerror = (event) => {
        console.log("error occured");
      };
      transaction.oncomplete = () => {
        db.close();
        getAllProduct("get", "");
      };
    };
  };

  const getBrands = async (region: string, locale: string) => {
    const brandsres = await ListBrands(region, locale);
    setBrands(brandsres);
  };

  return (
    <BimlibContext.Provider
      value={{
        basket,
        productsDetail,
        brands,
        allDownloadedFiles,
        toggleBasketProduct,
        addRemoveProduct,
        initBasket,
        addDownloadFiles,
        removeBasketProduct,
        getAllProduct,
        addDeletedFiles,
        removeProduct,
        count,
      }}
    >
      {children}
    </BimlibContext.Provider>
  );
};

export default BasketProvider;
