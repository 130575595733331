import { useEffect, useState } from "react";
import * as DOMPurify from "dompurify";

DOMPurify.addHook("uponSanitizeElement", (node: any, data: any) => {
  if (data.tagName === "iframe") {
    const src = node.getAttribute("src") || "";
    if (!src.startsWith("https://www.youtube.com/embed/")) {
      return node.parentNode?.removeChild(node);
    }
    node.setAttribute("sandbox", "allow-scripts allow-same-origin");
    node.setAttribute("allowfullscreen", "true");
  }
});

export function useSanitizedHtml(htmlContent: string): string {
  const [html, setHtml] = useState("");

  useEffect(() => {
    setHtml(getSanitizedHtmlString(htmlContent));
  }, [htmlContent]);

  return html;
}

export const getSanitizedHtmlString = (htmlContent: string): string => {
  return DOMPurify.sanitize(htmlContent, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["target", "sandbox", "allowfullscreen"],
  });
};
